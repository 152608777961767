import React from 'react';
import "./css/imageBox.css";

export default function imageBox({ imageUrl, title,description, buttonText, buttonLink }) {
  return (
    <div className="image-box">
      <img className="image-box-image" src={imageUrl} alt={title} />
      <div className="image-box-content">
        <h2 className="image-box-title">{title}</h2>
        <p className="image-box-description">{description}</p>
        <a className="image-box-button" href={buttonLink}>{buttonText}</a>
      </div>
    </div>
  )
}
