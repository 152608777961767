import "./css/Product.css"
import React from "react";

const Product = ({ image, name, rating, oldPrice, newPrice, categories }) => {
  return (
    <div className="product">
      <div className="product-image-container">
        <img src={image} alt={name} className="product-image" />
      </div>
      <div className="product-details">
        <h2 className="product-name">{name}</h2>
        <div className="product-rating">Rating: {rating} stars</div>
        <div className="product-prices">
          <span className="product-old-price">${oldPrice}</span>
          <span className="product-new-price">${newPrice}</span>
        </div>
        <div className="product-categories">
          {/* Categories: {categories.join(", ")} */}
        </div>
      </div>
    </div>
  );
};

export default Product;

