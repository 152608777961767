import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Coming from "./pages/comingsoon";
import Homepage from "./pages/homepage";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/*" element={<Coming />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;