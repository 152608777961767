import React from "react";
import { Link } from "react-router-dom";
import "./css/comingsoon.css";
export default function ErrorPage() {
  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center">Coming Soon </h1>
              </div>
              <div className="contant_box_404">
                <Link to="/" className="link_404">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}