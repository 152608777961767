import React from "react";
import Countdown from "react-countdown";
import "./css/flashcard.css"; // Create a CSS file for styling

const FlashSaleCard = ({ title, image, price, endTime, onBuyNowClick }) => {
  return (
    <div className="flash-sale-card">
      <div className="flash-sale-timer">
        <Countdown date={endTime} />
      </div>
      <img src={image} alt={title} className="product-image" />
      <h3 className="product-title">{title}</h3>
      <p className="product-price">${price}</p>
      <button className="buy-now-button" onClick={onBuyNowClick}>
        Buy Now
      </button>
    </div>
  );
};

export default FlashSaleCard;
