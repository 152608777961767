import ImageBox from './imageBox';
import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/ImageSlider.css"

class MultipleItems extends Component {
  static defaultProps = {
    items: [], // Provide an empty array as the default value
  };
  render() {
    const { items } = this.props;
    
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
    };

    return (
      <div>
        <Slider {...settings}>
          {items.map((item, index) => (
            <div key={index}>
              <ImageBox imageUrl={item.imageUrl} title={item.title} description={item.description} buttonLink={item.buttonLink} buttonText={item.buttonText} />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default MultipleItems;
