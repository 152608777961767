import React, { Component } from "react";
import Slider from "react-slick";
import Product from "./Product"; // Import your Product component

export default class MultipleRows extends Component {
  render() {
    const { products } = this.props; // Get products from props
    const settings = {
      // className: "center",
      // centerMode: true,
      // infinite: true,
      // centerPadding: "60px",
      slidesToShow: 1,
      speed: 500,
      rows: 2,
      slidesPerRow: 3
    };

    return (
      <div>
        <Slider {...settings}>
          {products.map((product, index) => (
            <div key={index}>
              <Product {...product} />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}
