import React from 'react';
import "./css/homepage.css";
import ImageBox from "./component/imageBox";
import Image1 from "../img/Basket.jpg"
import Image2 from "../img/fruits-vegetables.jpg"
import Image3 from "../img/table-with-vegetables.jpg"
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import ImageSlider from "./component/ImageSlider";
import FlashSale from "./component/FlashSale";
import MultipleRows from './component/MultiRowSlider';
import Product from './component/Product';

export default function homepage() {
    const endTime = new Date().getTime() + 3600000;

    const productsData = [
        {
          image: Image2,
          name: "Product 1",
          stars: 4.5,
          oldPrice: 49.99,
          newPrice: 39.99
        },
        {
          image: Image2,
          name: "Product 2",
          stars: 4.2,
          oldPrice: 59.99,
          newPrice: 49.99
        },
        {
          image: Image2,
          name: "Product 3",
          stars: 4.2,
          oldPrice: 59.99,
          newPrice: 49.99
        },
        {
          image: Image2,
          name: "Product 4",
          stars: 4.2,
          oldPrice: 59.99,
          newPrice: 49.99
        },
        {
          image: Image2,
          name: "Product 5",
          stars: 4.2,
          oldPrice: 59.99,
          newPrice: 49.99
        },
        {
          image: Image2,
          name: "Product 6",
          stars: 4.2,
          oldPrice: 59.99,
          newPrice: 49.99
        },
        // Add more product data as needed
      ];





  return (
    <div className='wrapper'>
        <div className='home-banners'>
            <div className='left-banner'>
                <ImageBox imageUrl={Image3} title={"My Image3"} buttonText={"Click Here"} />
            </div>
            <div className='right-banner'>
                <div class="first-row">
                    <div>
                        <ImageBox imageUrl={Image3} title={"My Image"} buttonText={"Click Here"} />
                    </div>
                    <div>
                    <ImageBox imageUrl={Image2} title={"My Image2"} description={"Hello This is demo Text"} buttonText={"Click Here"} />
                    </div>
                </div>
                <div class="second-row">
                    <div>
                        <ImageBox imageUrl={Image1} title={"My Image"} buttonText={"Click Here"} /> </div>
                </div>
            </div>
        </div>
        <div className="services">
            <div className="service">
               <div className="icon">
                    <HiOutlineRocketLaunch />
               </div>
               <div className="data">
                <p className="heading">Super-Fast Delivery</p>
                <p className="text"></p>
               </div>
            </div>
            <div className="service">
               <div className="icon">
                    <HiOutlineRocketLaunch />
               </div>
               <div className="data">
                <p className="heading">Super-Fast Delivery</p>
                <p className="text"></p>
               </div>
            </div>
            <div className="service">
               <div className="icon">
                    <HiOutlineRocketLaunch />
               </div>
               <div className="data">
                <p className="heading">Super-Fast Delivery</p>
                <p className="text"></p>
               </div>
            </div>
            <div className="service" style={{borderRight:'none'}}>
               <div className="icon">
                    <HiOutlineRocketLaunch />
               </div>
               <div className="data">
                <p className="heading">Super-Fast Delivery</p>
                <p className="text"></p>
               </div>
            </div>
        </div>
        <div className="offers">
            <ImageSlider 
            items={[
                {
                  imageUrl: Image2,
                  title: "Slide 1",
                  description: "Description for Slide 1",
                  buttonText: "Learn More",
                  buttonLink: "https://example.com"
                },
                {
                  imageUrl: Image2,
                  title: "Slide 2",
                  description: "Description for Slide 1",
                  buttonText: "Learn More",
                  buttonLink: "https://example.com"
                },
                {
                  imageUrl: Image3,
                  title: "Slide 3",
                  description: "Description for Slide 1",
                  buttonText: "Learn More",
                  buttonLink: "https://example.com"
                },
                {
                  imageUrl: Image2,
                  title: "Slide 4",
                  description: "Description for Slide 1",
                  buttonText: "Learn More",
                  buttonLink: "https://example.com"
                },
                // Add more items as needed
              ]}
            />
        </div>
        <div className="flashsales">
            <div className="flash">
                <h2>Flash Sale !!!</h2>
                <FlashSale  title= "Product Title"
                    image= {Image2}
                    price= "99.99"
                    endTime= {endTime} />
            </div>
            <div className="newest">
                <h2>Recommended Products</h2>
                <MultipleRows products={productsData} />
            </div>
        </div>
        <div className="best">
            <h1>Our Top Sellers</h1>
            <div className="top-seller">
            <div className="one">
                <Product image={Image2} name={"Product1"} rating={"4.5"} oldPrice={"450"} newPrice={"400"} />
                <Product image={Image2} name={"Product1"} rating={"4.5"} oldPrice={"450"} newPrice={"400"} />
            </div>
            <div className="one">
                <Product image={Image2} name={"Product1"} rating={"4.5"} oldPrice={"450"} newPrice={"400"} />
                <Product image={Image2} name={"Product1"} rating={"4.5"} oldPrice={"450"} newPrice={"400"} />
            </div>
            <div className="one">
                <Product image={Image2} name={"Product1"} rating={"4.5"} oldPrice={"450"} newPrice={"400"} />
                <Product image={Image2} name={"Product1"} rating={"4.5"} oldPrice={"450"} newPrice={"400"} />
            </div>
            
        </div>
        </div>
        
    </div>
  )
}
